<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
    :description="description"
  >
    <template slot-scope="{ attrs, listeners }">
      <div :class="placeholder ? 'prepend-input' : ''">
        <span>
          <slot name="placeholder">{{ placeholder }}</slot>
        </span>
        <b-form-input
          v-bind="attrs"
          v-on="listeners"
          v-model="model"
          :type="type"
          :min="min"
          :max="max"
          :step="step"
          :placeholder="placeholderInput"
          :formatter="formatInput"
          :disabled="disabled"
        />
      </div>
    </template>
  </form-group-thux-horizontal>
</template>

<script>
export default {
  name: 'form-input-thux',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: {},
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},

    // TEXT INPUT FIELDS
    disabled: { default: false },
    value: { required: true },
    placeholder: {},
    placeholderInput: {},
    formatter: { type: Function, default: undefined },
    description: {},
    min: {},
    max: {},
    step: {},
    maxlength: {},
    type: { default: 'text' }
  },
  watch: {
    value () {
      this.$set(this, 'model', this.value)
    },
    model () {
      this.$emit('change', this.model)
    }
  },
  data () {
    return {
      model: undefined
    }
  },
  created () {
    this.$set(this, 'model', this.value)
  },
  methods: {
    formatInput (e) {
      if (this.formatter) {
        return this.formatter(e)
      }
      if (this.maxlength) {
        const value = String(e).substring(0, this.maxlength)
        if (this.max || parseInt(this.max) === 0) {
          if (parseInt(value) > parseInt(this.max)) {
            return null
          }
        }
        if (this.min || parseInt(this.min) === 0) {
          if (parseInt(value) < parseInt(this.min)) {
            return null
          }
        }
        return value
      }
      if (this.maxlength) {
        return String(e).substring(0, this.maxlength)
      }
      if (this.min || parseInt(this.min) === 0) {
        if (parseInt(e) < parseInt(this.min)) {
          return null
        }
      }
      return e
    }
  }
}
</script>
