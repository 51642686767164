<template>
  <input
    v-if="!selectAll"
    type="checkbox"
    :checked="value"
    @change="$emit('set-selected-row')"
  />
  <input
    v-else
    type="checkbox"
    :checked="true"
    @change="$emit('set-selected-row')"
  />
</template>

<script>
export default {
  name: 'ThuxTableChecksComponent',
  props: {
    selectAll: { required: true },
    value: { required: true }
  }
}
</script>
