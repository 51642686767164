<template>
  <div class="table-indicators">
    <span v-if="status">
      <i class="far fa-check-circle text-green" />
    </span>
    <span v-else>
      <i class="far fa-times-circle text-red" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'StatusIndicator',
  props: ['status']
}
</script>
