<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as SUBJECT_CONTACT_TYPES } from './store'
import { TYPES as CNT_ORG_TYPES } from '../../../organization-contact/store'
import { ROUTES as SUBJECT_CONTACT_ROUTES } from './router'

import ContactCreateMixin from '../../../components/contact/mixins/ContactCreateMixin'

export default {
  name: 'SubjectContactCreate',
  mixins: [ContactCreateMixin],
  data () {
    return {
      id: this.$route.params.cntId,
      routeNameDetail: SUBJECT_CONTACT_ROUTES.SUBJECT_CONTACT_DETAIL,
      SUBJECT_CONTACT_ROUTES,
      showDuplicatedContactModal: false
    }
  },
  computed: {
    ...mapGetters({
      ...SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.DETAIL.ACTIONS,
      getContactList: SUBJECT_CONTACT_TYPES.GENERIC.organization.subjectcontact.LIST.ACTIONS.getList,
      createOrganizationContact: CNT_ORG_TYPES.GENERIC.organization.organizationcontact.DETAIL.ACTIONS.create
    }),
    onCancel () {
      this.$emit('close-form')
    }
  }
}
</script>
