<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <template slot-scope="{ attrs, listeners }">
      <div class="input-group">
        <date-picker
          v-bind="attrs"
          v-on="listeners"
          v-model="model"
          :config="config"
          :min-date="minDate"
          :placeholder="$t(placeholder)"
          :disabled="disabled"
          @dp-change="$emit('change', $event)"
        />
        <div class="input-group-append">
          <label class="input-group-text">
            <i :class="type === 'time' ? 'fas fa-clock' : 'fas fa-calendar'"></i>
          </label>
        </div>
      </div>
    </template>
  </form-group-thux-horizontal>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FormThuxHorizontalDatePicker',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { required: true },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},

    // DATE FIELDS
    disabled: { default: false },
    value: {
      required: true
    },
    type: { required: true },
    minDate: {},
    customConfig: {},
    placeholder: {
      default: 'Enter the date'
    },
    rows: {}
  },
  watch: {
    minDate () {
      this.setConfig()
    },
    value () {
      this.$set(this, 'model', this.value)
    }
  },
  data () {
    return {
      model: undefined,
      config: undefined,
      icons: {
        time: 'fas fa-clock',
        date: 'fas fa-calendar',
        up: 'fas fa-angle-up',
        down: 'fas fa-angle-down',
        previous: 'fas fa-angle-left',
        next: 'fas fa-angle-right',
        today: 'fas fa-calendar-alt',
        clear: 'far fa-trash-alt',
        close: 'fas fa-times'
      }
    }
  },
  created () {
    this.$set(this, 'model', this.value)
    this.setConfig()
  },
  methods: {
    setConfig () {
      if (this.customConfig) {
        this.$set(this, 'config', this.customConfig)
        return
      }
      if (this.type === 'date') {
        // date config
        this.$set(this, 'config', {
          format: 'L',
          useCurrent: false,
          showClear: true,
          locale: this.$i18n.locale()
        })
      } else if (this.type === 'time') {
        // time config
        this.$set(this, 'config', {
          format: 'HH:mm',
          useCurrent: false,
          showClear: true,
          locale: this.$i18n.locale()
        })
      } else if (this.type === 'datetime') {
        // datetime config
        this.$set(this, 'config', {
          format: 'L LT',
          sideBySide: true,
          useCurrent: false,
          showClear: true,
          locale: this.$i18n.locale()
        })
      }
      this.config.minDate = moment('1800-01-01')
      if (this.minDate) {
        this.$set(this.config, 'minDate', moment(this.minDate))
      }
      this.$set(this.config, 'icons', this.icons)
    }
  }
}
</script>
