<script>

export default {
  name: 'PopulateSelectMixin',
  methods: {
    prepareListForSelectOld (originalList, valueFieldName, textFieldNameList) {
      const disabledList = []
      const enabledList = []
      if (originalList && Array.isArray(originalList)) {
        originalList.forEach(function (element) {
          let textOpt = ''
          let disabled = false
          textFieldNameList.forEach(function (key) {
            textOpt = `${textOpt} ${element[key]}`
          })
          if ('scopes' in element && element.scopes) {
            textOpt = `${textOpt} [${element.scopes}]`
          }
          if (element.status === 0) {
            textOpt = `(DISABLED) ${textOpt}`
            disabled = true
            disabledList.push({ value: element[valueFieldName], text: textOpt, disabled: disabled })
          } else {
            enabledList.push({ value: element[valueFieldName], text: textOpt, disabled: disabled })
          }
        })
      }
      return enabledList.concat(disabledList)
    },
    prepareListForSelectAdvSearch (originalList, valueFieldName, textFieldNameList) {
      const disabledList = []
      const enabledList = []
      if (originalList && Array.isArray(originalList)) {
        originalList.forEach(function (element) {
          let textOpt = ''
          if (Array.isArray(textFieldNameList)) {
            textFieldNameList.forEach(function (key) {
              textOpt = `${textOpt} ${element[key]}`
            })
          }
          if ('scopes' in element && element.scopes) {
            textOpt = `${textOpt} [${element.scopes}]`
          }
          if (element.status === 0) {
            textOpt = `(DISABLED) ${textOpt}`
            disabledList.push({ value: element[valueFieldName], text: textOpt })
          } else {
            enabledList.push({ value: element[valueFieldName], text: textOpt })
          }
        })
      }
      return enabledList.concat(disabledList)
    },
    prepareChoiceListForSelectOld (originalList, valueIndex, textIndex) {
      const enabledList = []
      if (originalList && Array.isArray(originalList)) {
        originalList.forEach(function (element) {
          enabledList.push({ value: element[valueIndex], text: element[textIndex] })
        })
      }
      return enabledList
    },
    prepareListForSelect (originalList, valueFieldName, textFieldNameList) {
      const disabledList = []
      const enabledList = []
      if (originalList && Array.isArray(originalList)) {
        originalList.forEach(function (instance) {
          const option = {
            value: instance[valueFieldName],
            status: instance.status
          }
          let textOpt = ''
          textFieldNameList.forEach(function (key) {
            textOpt = `${textOpt} ${instance[key]}`
          })
          option.name = textOpt
          if (instance.status === 0) {
            option.$isDisabled = true
            disabledList.push(option)
          } else {
            enabledList.push(option)
          }
        })
      }
      return enabledList.concat(disabledList)
    },
    prepareListForMultiSelect (originalList, valueFieldName, textFieldNameList) {
      const disabledList = []
      const enabledList = []
      if (originalList && Array.isArray(originalList)) {
        originalList.forEach(function (instance) {
          const option = {
            id: instance[valueFieldName],
            status: instance.status
          }
          let textOpt = ''
          textFieldNameList.forEach(function (key) {
            textOpt = `${textOpt} ${instance[key]}`
          })
          option.name = textOpt.trim()
          if (instance.status === 0) {
            option.$isDisabled = true
            disabledList.push(option)
          } else {
            enabledList.push(option)
          }
        })
      }
      return enabledList.concat(disabledList)
    },
    prepareChoiceListForSelect (originalList, valueIndex, textIndex) {
      const enabledList = []
      if (originalList && Array.isArray(originalList)) {
        originalList.forEach(function (element) {
          enabledList.push({ value: element[valueIndex], name: element[textIndex] })
        })
      }
      return enabledList
    }
  }
}

</script>
