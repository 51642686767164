<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :is-required="isRequired"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <multiselect-thux
      v-if="showMultiselect"
      :class="getBorderClass"
      :disabled="disabled"
      :label="labelSearch"
      attributetype="multiselect"
      :value="value"
      :options="options"
      :close-on-select="closeOnSelect"
      :placeholder="placeholder"
      :min-length="minLength"
      @search="search"
      @remove="remove"
      @select="select"
      @clear="clear"
    >
      <template v-if="customizeOption" slot="option" slot-scope="data">
        <div class="custom-option" v-html="getCustomOption(data)"></div>
      </template>
    </multiselect-thux>
    <slot v-else name="other-components"></slot>
  </form-group-thux-horizontal>
</template>

<script>
import MultiselectThux from '@/components/MultiselectThux'

export default {
  name: 'FormThuxHorizontalMultiselect',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { required: true },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    isRequired: { default: true },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},

    // MULTISELECT FIELDS
    showMultiselect: { default: true },
    customizeOption: { default: false },
    disabled: { default: false },
    labelSearch: {},
    value: { required: true },
    options: { required: true },
    closeOnSelect: { default: false },
    placeholder: { default: '' },
    optionValue: { default: 'name' },
    minLength: { default: 2 }
  },
  components: {
    'multiselect-thux': MultiselectThux
  },
  data () {
    return {
      isInitValue: true
    }
  },
  computed: {
    getBorderClass () {
      if (!this.isInitValue) {
        if (this.validator && this.validator.$invalid && (!this.value || (this.value && this.value.length === 0))) {
          return 'multiselect_with_border_red'
        } else if (this.validator && !this.validator.$invalid && this.value && this.value.length > 0) {
          return 'multiselect_with_border_active'
        } else {
          return 'multiselect_with_border'
        }
      }
      return 'multiselect_with_border'
    }
  },
  methods: {
    search (value) {
      this.$emit('search', value)
    },
    select (value) {
      this.$set(this, 'isInitValue', false)
      this.$emit('select', value)
    },
    remove (value) {
      this.$set(this, 'isInitValue', false)
      this.$emit('remove', value)
    },
    clear () {
      this.$set(this, 'isInitValue', false)
      this.$emit('clear')
    },
    getCustomOption (data) {
      const option = data.item.option
      let statusIcon = ''
      let optionClasses = ''
      if (option.status === 0) {
        statusIcon = '<i class="far fa-times-circle text-red mr-2"></i>'
        optionClasses = 'option-disabled'
      }
      return `${statusIcon} <span class="${optionClasses}">${
        option[this.optionValue]
      }</span>`
    }
  }
}
</script>
